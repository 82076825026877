import { HttpErrorResponse, HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '../services/toast.service';
import { ApiErrorResponse } from '@models/api/api-response';

export const toastInterceptor: HttpInterceptorFn = (req, next): Observable<HttpEvent<unknown>> => {
  const shouldSkipErrorToast = req.headers.get('skipErrorToast');
  const updatedRequest = req.clone({ headers: req.headers.delete('skipErrorToast') });
  const toastController = inject(ToastService);

  if (
    ['PUT', 'PATCH', 'POST', 'DELETE'].includes(updatedRequest.method.toUpperCase()) &&
    !shouldSkipErrorToast
  ) {
    return next(updatedRequest).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error.status !== 200) {
          // toastController.presentDangerToast(`An error occurred: ${error.message}`);
          console.log(error);
          if (error.error.error_code || error.error.error_code) {
            toastController.presentDangerToast(`${error.error.message} (${error.error.error_code})`);
          } else {
            toastController.presentDangerToast(`${error.statusText}`);
          }
        }
        return throwError(error);
      }),
    );
  } else {
    return next(updatedRequest);
  }
};
